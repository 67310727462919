import * as React from "react";
import Layout from "../components/layout";
// import * as sections from "../components/sections"
// import Fallback from "../components/fallback"
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../LinkTranslated";
import SimpleLocalize from "../SimpleLocalize";
import Preloader from "../components/preloader";
import { PageBanner } from "../components/sections/PageBanner";
import { PageNavBar } from "../components/sections/pageNavBar";
import { ValuePropsHome } from "../components/sections/ValuePropsHome";
import { ServiceFeatures } from "../components/sections/ServiceFeatures";
import { HowWeWork } from "../components/sections/HowWeWork";
import { PriceTable } from "../components/sections/PriceTable";
import { Cta } from "../components/sections/Cta";
import { ValueProposition } from "../components/sections/ValueProposition";
import { Jobs } from "../components/sections/Jobs";

export default function CustomerService(props) {
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "/js/slick-animation.min.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  return (
    <SimpleLocalize {...props}>
      <Layout>
        <LinkTranslated href="/">
          {/* <FormattedMessage id="header--service-1" /> */}
          {/* <PageBanner textId="header--service-1" imagePath="/img/page-banner.jpg"/> */}
        </LinkTranslated>
        <PageBanner textId="header--contact-2" imagePath="/img/mobile.jpg" />
        <PageNavBar
          fromId="header--home"
          currentId="header--contact-2"
        ></PageNavBar>

        <section class="about-us-wrapper ">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 mt-12 ps-lg-12">
                <div class="block-contents">
                  <div class="section-title">
                    <h5 class="textleft relative">Mega</h5>
                    <span>
                      <FormattedMessage id="header--contact-2" />
                    </span>
                    {/* <h2><FormattedMessage id="value--title-1" /><span><FormattedMessage id="value--title-2" /></span></h2> */}
                  </div>
                </div>
                <div class="about-info-contents">
                  <p>
                    <FormattedMessage id="cs--story" />
                  </p>
                </div>
              </div>

              <div class="single-feature-item">
                <div class="mt-40 content">
                  <p>
                    <span>
                      <div class="content">
                        <FormattedMessage id="cs--contact-title" />
                      </div>
                    </span>
                  </p>
                  <p>
                    <FormattedMessage id="cs--contact-1" />
                  </p>
                  <p>
                    <FormattedMessage id="cs--contact-2" />
                  </p>
                  <p>
                    <FormattedMessage id="cs--contact-3" />
                    <a href="mailto:info@megastorage.com.hk">
                      {" "}
                      info@megastorage.com.hk
                    </a>
                  </p>
                  <p>
                    <FormattedMessage id="cs--contact-4" />
                  </p>
                  <p>
                    <FormattedMessage id="cs--contact-5" />
                  </p>
                </div>
              </div>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3687.5227042408555!2d113.98414841499314!3d22.44698364322659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403fa9d0655b295%3A0x35a6c62578401673!2z6ae_5oiQ54mp5rWB!5e0!3m2!1szh-TW!2shk!4v1535427397104"
                width="800"
                height="600"
                frameborder="0"
                // style="border: 0px; opacity: 1; visibility: visible;"
                allowfullscreen=""
              ></iframe>
            </div>
            {/* <div
              class="mt-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src="/img/trucks.jpg" alt="" style={{ width: "70%" }} />
            </div> */}
          </div>
        </section>

        <ValueProposition />
      </Layout>
    </SimpleLocalize>
  );
}
